<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{ $t('betDetail.title') }} #{{ item.bet.transactionId || "No transaction ID" }}
    </template>
    <div>
      <div class="text-muted">
        <div class="table-responsive mb-0">
          <div class="w-100">
            <p class="mb-1">{{ $t('betDetail.date') }}:</p>
            <h5 class="font-size-16">
              <div class="createdAt" @mouseover="handleOver" @mouseleave="handleLeave">
                {{ moment.utc(item.createdAt).format("DD/MM/YYYY | HH:mm:ss") }}
                <span class="badge badge-time bg-primary rounded-pill">Server time:
                  {{
                    moment(item.createdAt).format("DD/MM/YYYY | HH:mm:ss")
                  }}</span>
              </div>
            </h5>
          </div>
          <div class="w-100 my-2 d-flex">
            <p>{{ $t('betDetail.result') }}:</p>
            <h5 :class="`ms-3 text-light p-1 ${colorResult(result)}`">
              {{ result >= 0 ? formatResult(result) : "Round opened" }}
            </h5>
          </div>
          <div class="w-100 mt-3 d-flex" v-if="jackpots.length">
            <p>{{ $t('betDetail.jackpots') }}:</p>
            <JackpotPill />
          </div>
          <div>
            <p class="mb-1">{{ $t('transactions.roundID') }} :</p>
            <h5 class="font-size-16">
              {{ item.round ? item.round.code : "No round" }}
            </h5>
          </div>
          <div v-if="Object.entries(item).length">
            <p class="mt-3 mb-1">{{ $t('betDetail.roulette') }}:</p>
            <h5 class="font-size-16">
              {{ item.game && item.game.name }}
            </h5>
          </div>
          <div v-if="Object.entries(item).length">
            <p class="mt-3 mb-1">{{ $t('betDetail.user') }}:</p>
            <h5 class="font-size-16">
              {{ item.player && item.player.username }}
            </h5>
          </div>
        </div>
        <div class="mt-3">
          <div>
            <div>
              <b-table striped hover :items="items" :fields="fields">
                <template #cell(number)="data">
                  <span v-if="data.item.number instanceof Array">
                    <span v-for="(number, index) in data.item.number" :key="index"
                      :class="`ms-3 text-light p-1 ${colorResult(number)}`">
                      {{ number }}
                    </span>
                  </span>
                  <span v-if="data.item.type && data.item.betType === 'color'">
                    <span :class="data.item.type === 'RED' ? 'red' : 'black'">{{
                      data.item.type
                    }}</span>
                  </span>
                  <span v-if="data.item.type && data.item.betType !== 'color'">
                    <span class="green">{{ data.item.type }}</span>
                  </span>
                  <span v-if="!data.item.type && data.item.betType === 'plenoNumbers'
                  ">
                    <span :class="`ms-3 text-light p-1 ${colorResult(data.item.number)}`">{{ data.item.number }}</span>
                  </span>
                </template>
                <template #cell(earnings)="data">
                  {{ checkEarnings(data.item) }}
                </template>
                <template #cell(betType)="data">
                  {{
                    data.item.betType.includes("Numbers")
                      ? data.item.betType.replace("Numbers", "").toUpperCase()
                      : data.item.betType.replace("_", " ").toUpperCase()
                  }}
                </template>
                <template #cell(jacks)="data">
                  {{ thisBetIncludeJackpot(data.item) }}
                </template>
                <template #cell(earningsByJacks)="data">
                  {{ checkEarningsJackpot(data.item) }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between fw-bolder mx-auto w-75">
        <p>{{ $t('betDetail.totalBet') }}:</p>
        <p>
          {{ item.bet && item.amount }}
          {{ item.currency && item.currency[0].short }}
        </p>
      </div>
      <div class="d-flex justify-content-between fw-bolder mx-auto w-75" v-if="item.type === 'credit'">
        <p>{{ $t('betDetail.totalWin') }}:</p>
        <p>
          {{
            item.type === "credit"
              ? item.amount
              : 0
          }}
          {{ item.currency && item.currency[0].short }}
        </p>
      </div>
      <div v-if="item.type !== 'credit'">
        <p>
          <i>{{ $t('betDetail.info') }}</i>
        </p>
        <vs-button @click="calculatePayment"> {{ $t('betDetail.pay') }}</vs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { JackpotPill } from "../../../components";
export default {
  components: { JackpotPill },
  props: {
    item: {
      type: Object,
      default: () => { },
    },
    items: {
      type: Array,
      default: () => [],
    },
    result: {
      type: Number,
    },
    jackpots: {
      type: Array,
    },
    plenoAmount: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      colorResult: 'reports/colorResult',
      formatResult: 'reports/formatRouletteResults',
    })
  },
  data() {
    return {
      modal: false,
      fields: [
        {
          key: "betType",
          label: this.$t('betDetail.type'),
          class: "text-center",
        },
        {
          key: "number",
          label: this.$t('betDetail.number'),
        },
        {
          key: "jacks",
          label: this.$t('betDetail.jackpots'),
        },
        {
          key: "amount",
          label: this.$t('betDetail.amount'),
          class: "text-center",
        },
        {
          key: "earnings",
          label: this.$t('betDetail.win'),
          class: "text-center",
        },
        {
          key: "earningsByJacks",
          label: this.$t('betDetail.winWithJackpot'),
          class: "text-center",
        },
      ],
      betFields: [
        {
          key: "betType",
          label: this.$t('betDetail.type'),
        },
        {
          key: "number",
          label: this.$t('betDetail.number'),
        },
        {
          key: "jacks",
          label: this.$t('betDetail.jackpots'),
        },
        {
          key: "amount",
          label: this.$t('betDetail.amount'),
          class: "text-center",
        },
      ],
      dummie: [{ number: 1, amount: 100, type: "pleno" }],
      reds: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
      ],
    };
  },
  methods: {
    checkEarningsJackpot(bets) {
      const jackpot = this.jackpots.filter((jack) => {
        if (bets.betType === "plenoNumbers" && bets.number === jack.number) {
          return jack;
        }
      });

      if (!jackpot.length) {
        return this.$t('betDetail.noJackpot')
      }

      return bets.earnings ? bets.earnings : "Número no ganador";
    },
    checkEarnings(bets) {
      const jackpot = this.jackpots.filter((jack) => {
        if (bets.betType === "plenoNumbers" && bets.number === jack.number) {
          return jack;
        }
      });

      if (!jackpot.length) {
        return bets.earnings ? bets.earnings : "0";
      }

      return bets.earnings ? bets.amount * this.plenoAmount : "0";
    },
    thisBetIncludeJackpot(bets) {
      const jackpot = this.jackpots.filter((jack) => {
        if (bets.betType === "plenoNumbers" && bets.number === jack.number) {
          return jack;
        }
      });

      if (!jackpot.length) {
        return this.$t('betDetail.noJackpot')
      }

      return `X${jackpot[0].multiplier}`;
    },
    closeModa() {
      this.modal = false;
      this.items = [];
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
    async calculatePayment() {
      const { isConfirmed } = await Swal.fire({
        title: "¿Estás seguro de realizar el pago?",
        confirmButtonText: "Si",
        showDenyButton: true,
        denyButtonText: "No",
        icon: "question",
      });

      if (isConfirmed) {
        const betId = this.item.bet._id;
        const roundId = this.item.round._id;

        try {
          const { data } = await this.$http.post("bets/manual-pay-individual", {
            roundId,
            betId,
          });



          if (data.ok) {
            return Swal.fire({
              title: "Pago realizado correctamente",
              icon: "success",
            });
          }

          return Swal.fire({
            title: "Error realizando la transaccion",
            icon: "info",
          });
        } catch (error) {
          console.log("Error en pago individual", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.black {
  background: #000;
  color: #fff;
  padding: 2px;
  text-align: center;
}

.red {
  background: #f10;
  color: #fff;
  padding: 2px;
  text-align: center;
}

.green {
  background: #00a429;
  color: #fff;
  padding: 2px;
  text-align: center;
}

.jacks {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.badge-jack {
  position: absolute;
  top: -12px;
  right: -14px;
  z-index: 999999;
}
</style>
